import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  currentCurrency: {
    name: "United States dollar",
    code: "USD",
    symbol: "$",
  },
  portalLoaded: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "setCurrentCurrency": {
      return {
        ...state,
        currentCurrency: action.currency,
      };
    }

    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
